<template>
    <div class="good-box">
        <ul class="tabs">
            <li :class="shopType === 'is_new' ? 'lastActive' : ''" @click="getList('is_new')">
                <p class="lastTitle">新产品</p>
                <span>精选好物</span>
            </li>
            <li :class="shopType === 'is_hot' ? 'lastActive' : ''" @click="getList('is_hot')">
                <p class="lastTitle">热卖</p>
                <span>低价热卖</span>
            </li>
            <li :class="shopType === 'is_pt' ? 'lastActive' : ''" @click="getList('is_pt')">
                <p class="lastTitle">拼团</p>
                <span>优惠团购</span>
            </li>
        </ul>
        <van-pull-refresh style="width: 100%" v-model="refreshing" @refresh="onRefresh">
            <van-list
                class="vanList"
                :loading="loading"
                :finished="finished"
                finished-text="没有更多了"
                @load="onLoad"
            >
                <div class="good-item" v-for="item in shopList" :key="item.id" :title="item" @click="onClick(item.id)">
                    <img :src="item.picture">
                    <div class="good-desc">
                        <div class="title">{{ item.name }}</div>
                        <div class="price">
                            <span>¥ {{ item.price }}</span>
                            <span class="addIcon"><van-icon name="plus" color="#fff" /></span>
                        </div>
                    </div>
                </div>
            </van-list>
        </van-pull-refresh>
    </div>
</template>

<script>
    import {reactive, toRefs, ref} from 'vue'
    import { useRouter } from 'vue-router'
    import { getProduct } from '@/service/home'
    export default {
        setup() {
            const router = useRouter()
            const loading = ref(false);
            const finished = ref(false);
            const refreshing = ref(false);
            const state = reactive({
                // 商品列表
                shopList: [],
                // 商品类型
                shopType: 'is_new',
                // 当前分页数
                page: 0
            })
            const onLoad = async () => {
                if (loading.value) return
                // 将 loading 设置为 true，表示处于加载状态
                loading.value = true;
                setTimeout(async () =>{
                    try {
                        const page = state.page + 1
                        const val = state.shopType
                        let params = {}
                        if(val === 'is_new') {
                            params = {
                                page,
                                is_new: true
                            }
                        } else if (val === 'is_hot') {
                            params = {
                                page,
                                is_hot: true
                            }
                        } else{
                            params = {
                                page,
                                is_pt: true
                            }
                        }
                        if (refreshing.value) {
                            state.shopList = [];
                            refreshing.value = false;
                        }
                        const {data} = await getProduct(params)
                        state.page = page
                        state.shopList.push(...data)
                        if (data.length === 0) {
                            finished.value = true;
                        }
                    }catch (error)  {
                        console.error(error)
                    }
                    loading.value = false;
                }, 500)
            };
            const onRefresh = () => {
                // 清空列表数据
                finished.value = false;
                onLoad();
            };
            // 获取商品列表
            const getList = async (val) => {
                state.shopType = val
                state.page = 1
                finished.value = false
                let params = {}
                state.shopList = []
                if(val === 'is_new') {
                    params = {
                        page: state.page,
                        is_new: true
                    }
                } else if (val === 'is_hot') {
                    params = {
                        page: state.page,
                        is_hot: true
                    }
                } else{
                    params = {
                        page: state.page,
                        is_pt: true
                    }
                }
                const {data} = await getProduct(params)
                state.shopList = data
            }
            function onClick(id) {
                router.push({path: '/good/details', query: {id: id}})
            }
            return {
                getList,
                onClick,
                onLoad,
                onRefresh,
                loading,
                finished,
                refreshing,
                ...toRefs(state)
            }
        },
    }
</script>

<style scoped lang="less">
    .tabs{
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin: 0 40px;
        li{
            text-align: center;
            font-size: 12px;
            color: #999;
            margin-bottom: 18px;
            .lastTitle{
                font-size: 16px;
                font-weight: bold;
                margin-bottom: 5px;
                color: #333;
            }
        }
        li.lastActive{
            .lastTitle{
                color: red;
            }
            span{
                color: #fff;
                background: red;
                padding: 3px 6px;
                border-radius: 20px;
            }
        }
    }
    .good-box {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        background: #f9f9f9;
        .good-item {
            box-sizing: border-box;
            width: 48%;
            margin-bottom: 12px;
            padding: 10px 10px 0;
            background: #fff;
            border-radius: 10px;
            img {
                display: block;
                width: 120px;
                margin: 0 auto;
            }
            .good-desc {
                text-align: center;
                font-size: 14px;
                padding: 4px 0 12px;
                .title {
                    color: #222333;
                    background: #fff;
                    margin: 0;
                    text-align: left;
                    font-weight: normal;
                    font-size: 12px;
                    padding: 0;
                }
                .name{
                    width: 90%;
                    margin: 6px 0 0;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                .price {
                    display: flex;
                    justify-content: space-between;
                    margin-top: 6px;
                    color: red;
                    font-weight: bold;
                }
                .addIcon{
                    display: inline-block;
                    width: 20px;
                    height: 20px;
                    text-align: center;
                    line-height: 20px;
                    background: red;
                    border-radius: 50%;
                }
            }
        }
    }
    .vanList{
        margin: 0 16px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    /deep/ .van-list__finished-text{
        width: 100%;
        text-align: center;
    }
</style>
