<template>
    <div class="hello">
        <div class="detailed-introduce">
            <div class="intro-content" ref="content">
                <span class="merchant-desc" v-if="introduce" ref="desc">{{introduce}}</span>
                <div class="unfold" @click="clickTotal" v-if="showExchangeButton">显示更多</div>
            </div>
        </div>
    </div>
</template>

<script>
    import { onMounted, reactive, toRefs, ref, nextTick } from 'vue'
    // import router from '../../router'
    export default {
        props: {
            introduce: {
                type: String,
                default: ''
            }
        },
        setup (props) {
            const state = reactive({
                showTotal: false, // 是否展示所有文本内容
                showExchangeButton: false, // 是否显示展开收起按钮
                showLine: 3 // 显示的文本行数
            })

            const introduce1 = ref(' ') // 初始化文本
            const desc = ref(null) // 文本组件

            onMounted(() => {
                // 获取初始化数据
                loadInfo()
            })

            /** 文本的操作方法 */
            const txtMethods = {
                /** 将HTML文本转化为文字文本 */
                toText: (HTML) => {
                    var input = HTML
                    return input.replace(/<(style|script|iframe)[^>]*?>[\s\S]+?<\/\1\s*>/gi, '').replace(/<[^>]+?>/g, '').replace(/\s+/g, ' ').replace(/ /g, ' ').replace(/>/g, ' ')
                },

                /** 截取三行文本 */
                txtDec: () => {
                    if (!desc.value) {
                        return
                    }
                    if (introduce1.value) { // 对页面中的元素重新赋值，否则会采用之前裁剪过的文本
                        desc.value.innerHTML = introduce1.value
                    } else {
                        return
                    }

                    nextTick(() => {
                        let rows = desc.value.getClientRects().length // 文本行数
                        let txt = introduce1.value // 文本
                        // 文本为3行时的字数长度
                        let tl = 0 // eslint-disable-line no-unused-vars
                        if (rows < state.showLine) { // 未超出行数，返回
                            desc.value.innerHTML = introduce1.value
                            // state.seriesInfo.introduction.length - introduce.value.length > 10 ? state.showExchangeButton = true : state.showExchangeButton = false // html元素大于30个字符，显示展开按钮
                            return
                        }

                        // 数据量大时速度太慢，需优化(二分法？)
                        while (rows > state.showLine) { // 超出，遍历文字并进行截取，直到文本小于三行
                            let step = 1 // 末尾扣除的字数
                            if (/<br\/>$/.test(txt)) { // 回退的时候，如果碰到换行要整体替换
                                step = 5
                            }
                            txt = txt.slice(0, -step) // 截取
                            desc.value.innerHTML = txt
                            rows = desc.value.getClientRects().length
                            tl += step
                        }
                        if (txt.charCodeAt(txt.length - 1) < 255) { // 文字末尾留三个字符的空并加省略号(中文字符和英文字符留空大小不同，中文-3，英文-8)
                            desc.value.innerHTML = txt.slice(0, -8) + '...'
                        } else {
                            desc.value.innerHTML = txt.slice(0, -3) + '...'
                        }
                        state.showExchangeButton = true // 显示展开收起按钮
                        state.exchangeButton = true
                    })
                }
            }

            /** 防抖函数 */
            const debounce = (fn, wait) => {
                let timer2 = null
                return function () {
                    if (timer2 !== null) {
                        clearTimeout(timer2)
                    }
                    timer2 = setTimeout(fn, wait)
                }
            }

            /** 点击展开/收起按钮 */
            const clickTotal = () => {
                // router.push({ path: '/overflow/details' })
            }

            /** 窗口尺寸改变时重新计算文本的显示长度：使用防抖函数实现 */
            window.addEventListener('resize', debounce(txtMethods.txtDec, 200))

            /** 数据初始化 */
            const loadInfo = () => {
                // 使用封装的api从后台接口获取描述信息（使用自己的接口地址）
                introduce1.value = props.introduce
                txtMethods.txtDec()
            }

            return {
                ...toRefs(state),
                introduce1,
                desc,
                clickTotal
            }
        }
    }
</script>

<style scoped lang="less">
    .detailed-introduce {
        padding: 10px;
        margin: 0 auto;
        height: 100%;
        font-size: 14px;
        color: #627081;
        position: relative;
        overflow: hidden;

    .intro-content {
        overflow: hidden;
        text-align: left;

    .merchant-desc {
        width: 100%;
        line-height: 20px;
    }

    .unfold {
        text-indent: 0;
        z-index: 11;
        height: 20px;
        position: absolute;
        right: 15px;
        bottom: 10px;
        background: #f5f6f7;
        opacity: 1;
        cursor: pointer;
        color: #2878ff;
    }
    }
    }

</style>
